import React from "react";
import ReactDOM from "react-dom/client";
import { Store } from "@ludens-reklame/rubics-v3-sdk/store/types";
import { Section } from "@ludens-reklame/rubics-v3-sdk/section/types";
import { App } from "./app";

const Header = React.lazy(() => import("./components/header/header.js"));
const Footer = React.lazy(() => import("./components/footer/footer.js"));
const Video = React.lazy(() => import("./components/video/video.js"));
const Hero = React.lazy(() => import("./components/hero/hero.js"));
const ImageGrid = React.lazy(
  () => import("./components/imageGrid/imageGrid.js")
);
const InspirationalGrid = React.lazy(
  () => import("./components/inspirationalGrid/inspirationalGrid.js")
);
const RichTextBox = React.lazy(
  () => import("./components/richTextBox/richTextBox.js")
);
const ProductPlug = React.lazy(
  () => import("./components/productPlug/productPlug.js")
);
const ImageText = React.lazy(
  () => import("./components/imageText/imageText.js")
);
const CTABanner = React.lazy(
  () => import("./components/ctaBanner/ctaBanner.js")
);
const ProjectHero = React.lazy(
  () => import("./components/projectHero/projectHero.js")
);
const TextContent = React.lazy(
  () => import("./components/textContent/textContent.js")
);
const Employees = React.lazy(
  () => import("./components/employees/employees.js")
);

const ImageAndTextBanner = React.lazy(
  () => import("./components/imageAndTextBanner/imageAndTextBanner.js")
);
const SelectedPages = React.lazy(
  () => import("./components/selectedPages/selectedPages.js")
);
const ImportantMessage = React.lazy(
  () => import("./components/importantMessage/importantMessage.js")
);
const ContactForm = React.lazy(
  () => import("./components/contactForm/contactForm.js")
);

declare var _STORE: Store;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App store={_STORE} renderer={renderSection} />
  </React.StrictMode>
);

function renderSection(
  section: Section,
  children?: React.ReactNode
): React.ReactNode {
  let suspend: boolean = true;
  let Component: React.ComponentType<any>;
  const domElem = document.getElementById(section.name);

  switch (section.component) {
    case "ImportantMessage":
      Component = ImportantMessage;
      break;
    case "ImageAndTextBanner":
      Component = ImageAndTextBanner;
      break;
    case "SelectedPages":
      Component = SelectedPages;
      break;
    case "TextContent":
      Component = TextContent;
      break;
    case "CTABanner":
      Component = CTABanner;
      break;
    case "ProjectHero":
      Component = ProjectHero;
      break;
    case "Employees":
      Component = Employees;
      break;
    case "ContactForm":
      Component = ContactForm;
      break;
    case "ImageText":
      Component = ImageText;
      break;
    case "ProductPlug":
      Component = ProductPlug;
      break;
    case "RichTextBox":
      Component = RichTextBox;
      break;
    case "Hero":
      Component = Hero;
      break;
    case "ImageGrid":
      Component = ImageGrid;
      break;
    case "InspirationalGrid":
      Component = InspirationalGrid;
      break;
    case "Video":
      Component = Video;
      break;
    case "Footer":
      Component = Footer;
      break;
    case "Header":
      Component = Header;
      break;
    default:
      return null;
  }

  if (!suspend) {
    return <Component {...section.props}>{children}</Component>;
  }

  return (
    <React.Suspense
      fallback={
        domElem ? (
          <div dangerouslySetInnerHTML={{ __html: domElem.innerHTML }} />
        ) : null
      }
    >
      {/* @ts-ignore */}
      <Component {...section.props}>{children}</Component>
    </React.Suspense>
  );
}
